import React from 'react';
import { PropTypes } from 'prop-types';
import './PlaceholderBlock.scss';

function PlaceholderBlock({
  title,
  subTitle,
  subTitleLink,
  className,
  maxContentWidth,
}) {
  return (
    <div
      className={
        'd-flex flex-column placeholder-block flex-items-center flex-justify-center text-center' +
        (className ? ' ' + className : '')
      }
    >
      <div className="px-3" style={{ maxWidth: maxContentWidth || 'none' }}>
        <h4 className="text-center">{title}</h4>
        {subTitle &&
          (subTitleLink ? (
            <a href={subTitleLink} target="_blank" rel="noreferrer">
              {subTitle}
            </a>
          ) : (
            <span>{subTitle}</span>
          ))}
      </div>
    </div>
  );
}

PlaceholderBlock.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleLink: PropTypes.string,
  className: PropTypes.string,
  maxContentWidth: PropTypes.number,
};

export default PlaceholderBlock;
