//
// Tuva Showdown Extension
// @author Naveenkumar Ganesan
//
/*jshint scripturl:true*/

import showdown from 'showdown';
import { TUVA_TOOL_MENUS } from './Util';

(async () => {
  // this api is only for doctrina. so added restriction for tuvalabs
  if (window.location.hostname.indexOf('tuvalabs.com') === -1) {
    const response = await fetch('/api/lookup/partner_icons/');
    try {
      const data = await response.json();
      window.partnerIcons = data;
    } catch (err) {
      // mute the error
    }
  }
})();

const HTML_TAGS_LIST = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'menu',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'slot',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
];

const CUSTOM_ALERT_MARKDOWN_NAMES = [
  'ti-alert-objectives',
  'ti-alert-know',
  'ti-alert-home',
  'ti-alert-think',
];
const CUSTOM_ALERT_TITLE_VALUES = {
  'ti-alert-objectives': 'Learning Objectives',
  'ti-alert-know': 'Did You Know?',
  'ti-alert-home': 'Try at Home',
  'ti-alert-think': 'Think About It',
};

(function () {
  // Enable support to add Tables
  showdown.setOption('tables', true);
  showdown.setOption('strikethrough', true);
  showdown.setOption('literalMidWordUnderscores', true);

  function getNumberFromString(val) {
    if (isNaN(val)) {
      return 0;
    } else {
      return parseInt(val);
    }
  }

  // Find the button tree for a given icon name
  function getToolBtnTree(iconName) {
    var tree = [];
    if (window.tuvaToolsButtons) {
      function getTree(options, match) {
        for (var index = 0; index < options.length; index++) {
          if (options[index].value === match) {
            return [options[index].value];
          }
          if (options[index].children) {
            var matched = getTree(options[index].children, match);
            if (matched) {
              return [options[index].value].concat(matched);
            }
          }
        }
        return;
      }
      tree = getTree(window.tuvaToolsButtons, iconName);
    } else {
      tree = [iconName];
    }
    return tree;
  }

  var tuva = function () {
    return [
      // To open all link in new tab
      {
        type: 'output',
        regex: '<a(.*?)>',
        replace: function (match, content) {
          return content.indexOf('mailto:') !== -1 ||
            content.indexOf('javascript:') !== -1 ||
            content.indexOf('tel:') !== -1
            ? '<a' + content + '>'
            : '<a target="_blank"' + content + '>';
        },
      },
      /**
       * For MathJax
       * ===========
       * Decode the encoded mathjax specific contents after it is returned from showdown/markdown engine.
       * So the final output from this rules will be given to MathJAX converter
       **/
      {
        type: 'output',
        regex: /(\$\$)(.+?)(\$\$)/g,
        replace: function (match, first, value, last) {
          return '<span class="mathjax">' + decodeURI(value) + '</span>';
        },
      },
      /**
       * For MathJax
       * ===========
       * Encode the mathjax specific contents before it is passed to showdown/markdown engine.
       * So that we can avoid the mathjax syntax overriding by showdown/markdown rules.
       * Note:
       * Showdown converts $ sign to ¨D by default. Thats why the below rule checks for ¨D instead of $
       * Since we are allowing double or single $ to wrap mathjax syntax, we are checking for both $ and $$
       **/
      {
        type: 'lang',
        regex: /(¨D¨D)(.+?)(¨D¨D)/g,
        replace: function (match, first, value, last) {
          return first + encodeURI(value) + last;
        },
      },
      // Replacing < and > symbols to respective html encoded values
      // to avoid ignoring those texts during rendring by react engine
      {
        type: 'lang',
        regex: '<(.*?)>',
        replace: function (match, value) {
          if (
            value &&
            value.indexOf('class=') === -1 &&
            HTML_TAGS_LIST.indexOf(value.replace(/\//g, '')) === -1
          ) {
            return (
              '&lt;' +
              value.replace(/>/g, '&gt;').replace(/</g, '&lt;') +
              '&gt;'
            );
          }
          return match;
        },
      },
      // Applying paragraph font styles p0,p1,p2 for symbol +,++,+++
      {
        type: 'lang',
        filter: function (text, converter, options) {
          return text.replace(
            /^(\++) ?(.*)$/gm,
            function (match, plusSymbols, value) {
              return (
                '<p class="p' +
                plusSymbols.length +
                '">' +
                converter
                  .makeHtml(value)
                  .replace('<p>', '')
                  .replace('</p>', '') +
                '</p>'
              );
            },
          );
        },
      },
      // Tuva Attribute
      {
        type: 'lang',
        regex:
          /:ta\["([\u0590-\u08FF\u00A0-\u00FF&;<>A-Za-z0-9_ \=\-,.°#\^\(\)\[\]\\/%?]+)"\]/g,
        replace: function (match, attribute) {
          return '<span class="tuva-attr">' + attribute + '</span>';
        },
      },
      // Subscripts
      {
        type: 'lang',
        regex: /\^\^(.+?)\^\^/g,
        replace: function (match, value) {
          return '<sub>' + value + '</sub>';
        },
      },
      // Superscripts
      {
        type: 'lang',
        regex: /\^(.+?)\^/g,
        replace: function (match, value) {
          return '<sup>' + value + '</sup>';
        },
      },
      // Line Break
      {
        type: 'lang',
        regex: /;;br/g,
        replace: function (match) {
          return '<br/>\n';
        },
      },
      // Horzontal Line
      {
        type: 'lang',
        regex: /;;hr/g,
        replace: function (match) {
          return '<hr/>\n';
        },
      },

      // Partner Icons
      {
        type: 'lang',
        regex: /:pi\[("[\u0590-\u08FFA-Za-z0-9_ \-]+"|[A-Za-z0-9:_-]+)\]/g,
        replace: function (match, iconNamewithAlignment) {
          const icon_end_index = iconNamewithAlignment.lastIndexOf(':');
          let iconName, alignment;

          if (icon_end_index > 0) {
            iconName = iconNamewithAlignment.substring(0, icon_end_index);
            alignment = iconNamewithAlignment.substring(icon_end_index + 1);
          } else {
            iconName = iconNamewithAlignment;
            alignment = 'l';
          }

          const icon = window.partnerIcons.find(
            icon => icon.value === iconName,
          );
          if (alignment === 'l') {
            return (
              '<img src="' + icon.url + '" class="pr-1" style="float:left"/>'
            );
          } else if (alignment === 'r') {
            return (
              '<img src="' + icon.url + '" class="pl-1" style="float:right"/>'
            );
          } else {
            //alignment === 'n'
            return '<img src="' + icon.url + '" class="pl-1"/>';
          }
        },
      },
      // Tuva Icons
      {
        type: 'lang',
        regex: /:ti\[("[\u0590-\u08FFA-Za-z0-9_ \-]+"|[A-Za-z0-9_-]+)\]/g,
        replace: function (match, iconName) {
          if (match.indexOf('"') >= 0) {
            return (
              '<span class="tuva-icon tuva-icon-group">' +
              iconName.replace(/"/g, '') +
              '</span>'
            );
          } else {
            // To make the footer icons as clickable in the content area
            var iconNameKey = iconName;
            switch (iconName) {
              case 'ti-about-dataset':
              case 'ti-keywords':
              case 'ti-glossary':
              case 'ti-tutorial-video':
                iconName += ' markdown-icon-events-binder';
                break;
              default:
                break;
            }
            if (iconName.indexOf('markdown-icon-events-binder') > 0) {
              return (
                '<i class="tuva-icon tuva-icon-group ' +
                iconName +
                '" data-key="' +
                iconNameKey +
                '"><span class="sr-only">' +
                (TUVA_TOOL_MENUS[iconNameKey] || '') +
                '</span></i>'
              );
            } else {
              var result = '';
              var nodes = getToolBtnTree(iconName);
              if (nodes && nodes.length) {
                if (nodes[0] === 'tuva-jr') {
                  nodes = nodes.slice(1);
                }
                result += '<span class="tuva-icon-group">';
                nodes.forEach(function (iconName, index) {
                  if (index > 0) {
                    result += ' &gt; ';
                  }
                  result +=
                    '<i  class="tuva-icon ' +
                    iconName +
                    '"><span class="sr-only">' +
                    (TUVA_TOOL_MENUS[iconName] || '') +
                    '</span></i>';
                });
                result += '</span>';
              }
              return result;
            }
          }
        },
      },
      // For Youtube and Vimeo video or simulation
      {
        type: 'lang',
        regex: /(!!)\[(.*?)\]\[(.*?)\]\((.*?)\)/g,
        replace: function (
          match,
          exclamationMark,
          videoProvider,
          size,
          videoId,
        ) {
          videoProvider = videoProvider.toLowerCase();
          let printable = showdown.getOption('printable') === true,
            videoSize = size.toLowerCase().split('x'),
            width = videoSize[0],
            height = videoSize[1],
            startTime = 0,
            endTime = 0,
            videoURL = '';

          if (videoId.indexOf(':') > 1 && videoProvider !== 'iframe') {
            startTime = videoId.substring(videoId.indexOf(':') + 1);
            // Check for end time
            if (startTime.indexOf('-') > 1) {
              endTime = startTime.substring(startTime.indexOf('-') + 1);
              // Remove end time and assign start time
              startTime = startTime.substring(0, startTime.indexOf('-'));
            }

            // Remove timer from videoId
            videoId = videoId.substring(0, videoId.indexOf(':'));
          }

          // Fix numbers
          width = getNumberFromString(width) || 560;
          height = getNumberFromString(height) || 315;
          startTime = getNumberFromString(startTime);
          endTime = getNumberFromString(endTime);

          if (videoProvider === 'youtube') {
            if (printable) {
              const url =
                'https://www.youtube.com/watch?v=' +
                videoId +
                (startTime ? '&start=' + startTime : '') +
                (endTime ? '&end=' + endTime : '');

              return '<a target="_blank" href="' + url + '">' + url + '</a>';
            } else {
              videoURL =
                'https://www.youtube.com/embed/' +
                videoId +
                '?rel=0' +
                (startTime ? '&start=' + startTime : '') +
                (endTime ? '&end=' + endTime : '');

              return (
                '<iframe width="' +
                width +
                '" height="' +
                height +
                '" src="' +
                videoURL +
                '" frameborder="0" allowfullscreen></iframe>'
              );
            }
          } else if (videoProvider === 'vimeo') {
            if (printable) {
              const url =
                'https://vimeo.com/' +
                videoId +
                (startTime ? '#t=' + startTime : '');
              return '<a target="_blank" href="' + url + '">' + url + '</a>';
            } else {
              // Vimeo support only start time
              videoURL =
                'https://player.vimeo.com/video/' +
                videoId +
                (startTime ? '#t=' + startTime : '') +
                '?color=ffffff&title=0&byline=0&portrait=0';

              return (
                '<iframe width="' +
                width +
                '" height="' +
                height +
                '" src="' +
                videoURL +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
              );
            }
          } else if (videoProvider === 'simulation') {
            if (printable) {
              const url =
                'https://simulations.tuvalabs.com/simulations/' + videoId + '/';
              return '<a target="_blank" href="' + url + '">' + url + '</a>';
            } else {
              return (
                '<iframe frameBorder="0" width="' +
                width +
                '" height="' +
                height +
                '" src="https://simulations.tuvalabs.com/simulations/' +
                videoId +
                '/" ></iframe>'
              );
            }
          } else if (videoProvider === 'iframe') {
            if (printable) {
              return (
                '<a target="_blank" href="' + videoId + '">' + videoId + '</a>'
              );
            } else {
              return (
                '<iframe width="' +
                width +
                '" height="' +
                height +
                '" src="' +
                videoId +
                '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
              );
            }
          } else {
            return match;
          }
        },
      },
      // For full screen image view
      {
        type: 'lang',
        regex: /(!)\[(.*?)\]\[(.*?)\]\((.*?)\)/g,
        replace: function (match, exclamationMark, altText, _size, imageUrl) {
          const sizeSplits = _size ? _size.split(':') : [];
          const position = sizeSplits[1];
          let width = sizeSplits[0];
          let style = '';
          if (width?.indexOf('%') > 0) {
            // width support only if it has % symbol
            style = `style="max-width:${_size}"`;
          }
          let className = 'zoomable-image';
          if (position === 'lf') {
            className += ' float-left';
          } else if (position === 'rf') {
            className += ' float-right';
          }
          match = match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
          return `<div ${style} class="${className}">${match}</div>`;
        },
      },
      // For enhanced quote or Answer Tag
      {
        type: 'lang',
        filter: function (text, converter, options) {
          return text.replace(
            /(!-{3})([^]*?)(-{3}!)( ?{.*})?/g,
            function (match, startMatch, content, endMatch, styles) {
              styles = styles || '';
              // Remove the curly braces
              if (styles) {
                styles = styles.replace(/{/g, '').replace(/}/g, '');
              }

              // 1) Add green border and white background when the styles are not given in the markdown tag
              // 2) If styles are given in the markdown tag and it has styles for border-color then override the default green border with the border-color given in the markdown tag and append additional given styles
              // 3) If there is no border-color in the markdown tag styles then skip the border and background properties and set the styles was given in the markdown tag as it is. (In this case the following if condition will fail)
              if (!styles || styles.indexOf('border-color') >= 0) {
                styles =
                  'background-color:#fff;padding: 10px;border: 1px solid green;border-radius: 5px;margin: 10px 0 30px;' +
                  styles;
              }

              return (
                '<div' +
                (styles ? ' style="' + styles + '"' : '') +
                '>' +
                converter.makeHtml(content) +
                '</div>'
              );
            },
          );
        },
      },
      // For Tuva Alert
      {
        type: 'lang',
        filter: function (text, converter, options) {
          return text.replace(
            /(\[-{3})([^]*?)(-{3}\])( ?{[a-z0-9- ]+})?/g,
            function (match, startMatch, content, endMatch, _class) {
              _class = removeFirstAndLastChar(_class);
              let iconName = 'Stop';
              if (_class.startsWith('alert-')) {
                if (_class === 'alert-hand') {
                  _class = 'ti-alert-stop';
                } else {
                  _class = 'ti-' + _class;
                  iconName = _class.slice(9);
                  iconName =
                    iconName.charAt(0).toUpperCase() + iconName.slice(1);
                }
                if (CUSTOM_ALERT_MARKDOWN_NAMES.includes(_class)) {
                  iconName = CUSTOM_ALERT_TITLE_VALUES[_class];
                }
                _class += ' ti text-black pr-2';
              }
              return `<div class="tuva-alert bg-yellow-1 border-yellow">
                        <div class="tuva-alert-icon">
                          <i class="${_class}"></i>
                          <b>
                            ${iconName}
                          </b>
                        </div>
                        ${
                          !['', undefined, null, '\n', '\r'].includes(content)
                            ? `<div class="tuva-alert-content">
                                  ${converter.makeHtml(content)}
                               </div>`
                            : ''
                        }
                      </div>`;
            },
          );
        },
      },
      // Color in middle of sentence
      {
        type: 'lang',
        regex: /::color\[(#[0-9a-fA-F]{6}|[a-zA-Z]+)\|([^\]]+)\]::/g,
        replace: function (match, color, value) {
          return '<span style="color: ' + color + ';">' + value + '</span>';
        },
      },
    ];
  };

  // Client-side export
  showdown.extension('tuva', tuva);

  function removeFirstAndLastChar(text) {
    if (text) {
      text = text.trim();
      return text.substring(1, text.length - 1);
    } else {
      return '';
    }
  }
})();
