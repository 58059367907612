import React, { Component } from 'react';
import './CollapsibleSidebarLayout.scss';
import Button from '../Button/Button';

const ContentHeader = ({ children, className }) => {
  return (
    <div className={`d-flex ${className || ''}`.trim()}>
      <button
        id="collapsible-toggle-menu"
        className="bg-none border border-blue d-lg-none h4 px-3 mr-2 rounded-2"
      >
        <i className="ti ti-bars" aria-hidden="true" />
      </button>
      {children}
    </div>
  );
};

const Sidebar = ({
  children,
  toggleMenu,
  isOpen,
  className,
  width = 420,
  style,
}) => {
  return (
    <>
      {isOpen && (
        // Overlay
        <div
          className="d-block d-lg-none position-fixed height-full width-full top-0 left-0 bottom-0 right-0 drawer-overlay"
          onClick={() => {
            toggleMenu();
          }}
        ></div>
      )}
      <div
        className={`collapsible-sidebar bg-none overflow-auto border-gray-300 border-right float-left d-lg-block ${
          isOpen
            ? 'd-sm-block position-absolute position-lg-relative top-0 bottom-0'
            : 'd-sm-none'
        } ${className || ''}`.trim()}
        style={{ ...style, width }}
      >
        <Button
          className="bg-none border-0 d-lg-none float-right p0 pt-0"
          onClick={e => {
            toggleMenu();
          }}
        >
          <i className="ti ti-close" />
        </Button>

        {children}
      </div>
    </>
  );
};
// Setting display name
Sidebar.displayName = 'Sidebar';

const Content = ({ children, className, fixedClassName }) => {
  let defaultClass = 'flex-1 float-left overflow-auto ';
  return (
    <div
      className={`${fixedClassName ? fixedClassName : defaultClass} ${
        className || ''
      }`.trim()}
    >
      {children}
    </div>
  );
};
// Setting display name
Content.displayName = 'Content';

class CollapsibleSidebarLayout extends Component {
  static ContentHeader = ContentHeader;
  static Content = Content;
  static Sidebar = Sidebar;

  constructor(props) {
    super(props);

    // Set the state directly. Use props if necessary.
    this.state = {
      isOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentDidMount() {
    this.registerClickEventForToggleBtn();
  }

  componentDidUpdate() {
    this.registerClickEventForToggleBtn();
  }

  registerClickEventForToggleBtn() {
    this.toggleBtn = document.getElementById('collapsible-toggle-menu');
    if (this.toggleBtn) {
      this.toggleBtn.onclick = this.toggleMenu;
    }
  }

  render() {
    const { children, className } = this.props;
    const { isOpen } = this.state;
    let newChildren = React.Children.map(children, child => {
      if (child.type.displayName === 'Sidebar') {
        child = React.cloneElement(child, {
          toggleMenu: this.toggleMenu,
          isOpen,
        });
      }
      return child;
    });
    return (
      // flex-justify-stretch flex-1 text-gray
      <div
        className={`collapsible-wrapper position-relative clearfix d-flex ${
          className || ''
        }`.trim()}
      >
        {newChildren}
      </div>
    );
  }
}

export default CollapsibleSidebarLayout;
