import React, { Component } from 'react';
import Select from '../multipleSelect/select';
import PropTypes from 'prop-types';
import { RateBlock } from '@tuva-ui/components/';

const NumberField = ({ className, onChange, name, id }) => {
  return (
    <input
      type="number"
      className={'width-full form-control ' + (className || '')}
      onChange={e => onChange(e)}
      name={name}
      id={id}
    ></input>
  );
};

const Date = ({ className, onChange, name, id }) => {
  return (
    <input
      type="date"
      className={'width-full form-control ' + (className || '')}
      onChange={e => onChange(e)}
      name={name}
      id={id}
    ></input>
  );
};

const PositiveNumberField = ({
  defaultValue,
  className,
  onChange,
  onBlur,
  name,
  autoComplete,
  autoFocus,
  debounceTimeout,
  style,
  id,
  max,
}) => {
  function debounce(callback, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  }
  let debouncedLog = debounce(e => onChange(e), debounceTimeout);

  return (
    <input
      type="number"
      min={0}
      max={max}
      className={'form-control ' + (className || '')}
      onChange={e => {
        if (e.target.checkValidity()) {
          if (debounceTimeout) {
            debouncedLog(e);
          } else {
            onChange(e);
          }
        } else {
          e.target.reportValidity();
        }
      }}
      id={id}
      style={style}
      name={name}
      autoFocus={autoFocus ? autoFocus : false}
      defaultValue={defaultValue || defaultValue === 0 ? defaultValue : ''}
      autoComplete={autoComplete}
      onKeyPress={function (e) {
        /* Allow only numbers */
        if (!/\d+/.test(e.key)) {
          e.preventDefault();
        }
      }}
      onBlur={function (e) {
        // Reset value on blur
        e.target.value = defaultValue;
        if (onBlur) {
          onBlur(e);
        }
      }}
      onPaste={function (e) {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseInt(clipboardData.getData('text'));

        if (pastedData > 0) {
          setTimeout(() => {
            e.target.value = pastedData;
          }, 50);
        } else {
          e.preventDefault();
        }
      }}
      onWheel={e => e.target.blur()}
    ></input>
  );
};

const RateBlockElement = ({ onChange, disabled, value, name }) => {
  return (
    <div className="d-flex flex-justify-center">
      <RateBlock
        setValue={rate => {
          onChange(rate, name);
        }}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

const Password = ({ className, onChange, name, placeholder, id }) => {
  return (
    <input
      type="password"
      className={'width-full form-control ' + (className || '')}
      onChange={e => onChange(e)}
      name={name}
      autoComplete={'off'}
      placeholder={placeholder}
      id={id}
    ></input>
  );
};

const TextArea = ({
  cols,
  rows,
  className,
  onChange,
  name,
  placeholder,
  defaultValue,
  id,
  disabled,
}) => {
  return (
    <textarea
      type="textarea"
      rows={rows || 4}
      cols={cols || 50}
      className={'width-full form-control ' + (className || '')}
      onChange={e => onChange(e)}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
    ></textarea>
  );
};

const TwitterTextArea = ({
  cols,
  rows,
  className,
  onChange,
  name,
  placeholder,
  defaultValue,
  value,
  maxSupport,
}) => {
  return (
    <div>
      <textarea
        type="textarea"
        rows={rows || 4}
        cols={cols || 50}
        className={className ? className : 'form-control twitter-textarea'}
        onChange={e => onChange(e)}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      ></textarea>
      <span className="text-gray-light float-right text-right">
        {maxSupport - (value ? value.length : 0)} remaining
      </span>
    </div>
  );
};

const Text = ({
  children,
  className,
  onChange,
  name,
  defaultValue,
  disabled,
  placeholder,
  id,
}) => {
  return (
    <input
      type="text"
      name={name}
      className={'width-full form-control ' + (className || '')}
      onChange={e => onChange(e)}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder}
      autoComplete={'off'}
      id={id}
    ></input>
  );
};

const SelectElement = ({
  children,
  className,
  onChange,
  name,
  options,
  placeholder,
  values,
  searchable,
  onSearch,
  autoFocus,
  displayKey,
  valueKey,
  id,
}) => {
  return (
    <Select
      placeholder={placeholder ? placeholder : 'Please select'}
      options={options}
      values={values ? values : ''}
      onChange={e => onChange(e, name)}
      searchable={searchable}
      onSearch={onSearch}
      autoFocus={autoFocus}
      className={className}
      displayKey={displayKey}
      valueKey={valueKey}
      id={id}
    />
  );
};

const RadioGroup = ({ children, className, onChange, name, type }) => {
  const newChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {
      name: name,
      type: type,
      onChange: (e, name) => {
        onChange(e, name);
      },
    });
  });
  if (type === 'vertical') {
    return (
      <div className="form-radio-button-group d-flex flex-column">
        {newChildren}
      </div>
    );
  }

  return <div className="form-radio-button-group d-flex">{newChildren}</div>;
};

const Checkbox = ({ children, className, onChange, name, value, checked }) => {
  return (
    <label className={'text-normal'}>
      <input
        className={className}
        type="checkbox"
        checked={checked}
        name={name}
        value={value}
        onChange={e => onChange(e)}
      />
      {value}
    </label>
  );
};

const CheckboxGroup = ({
  children,
  className,
  onChange,
  name,
  type,
  checkboxValues,
}) => {
  let checkedValues = checkboxValues;

  const newChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {
      name: name,
      type: type,
      checked: checkedValues?.includes(child.props.value) ? true : false,
      onChange: e => {
        if (e.target.checked) {
          checkedValues.push(e.target.value);
        } else {
          checkedValues = checkedValues.filter(elem => elem !== e.target.value);
        }
        e.target.value = checkedValues;
        onChange(e);
      },
    });
  });
  if (type === 'vertical') {
    return (
      <div className="form-radio-button-group d-flex flex-column">
        {newChildren}
      </div>
    );
  }

  return <div className="form-radio-button-group d-flex">{newChildren}</div>;
};

class Input extends Component {
  static Number = NumberField;
  static Text = Text;
  static Date = Date;
  static Select = SelectElement;
  static RadioGroup = RadioGroup;
  static CheckboxGroup = CheckboxGroup;
  static Checkbox = Checkbox;
  static Password = Password;
  static TextArea = TextArea;
  static RateBlock = RateBlockElement;
  static TwitterTextArea = TwitterTextArea;
  static PositiveNumber = PositiveNumberField;
  render() {
    const { children, ...props } = this.props;
    return <div {...props}>{children}</div>;
  }
}

Input.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([NumberField, TextArea, SelectElement, Password, Text]),
  ).isRequired,
};

NumberField.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

PositiveNumberField.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  debounceTimeout: PropTypes.number,
  style: PropTypes.object,
};

TextArea.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  cols: PropTypes.number,
  rows: PropTypes.number,
};

Password.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

Text.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

SelectElement.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  values: PropTypes.any,
  searchable: PropTypes.bool,
  onSearch: PropTypes.func,
  autoFocus: PropTypes.bool,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
};

export default Input;
