import React, { useEffect, useState } from 'react';
import { StepLocalStorageUtil, TextSizePicker } from '@tuva-ui/components';
import FilterDropDown from './FilterDropDown.js';

let filterOptionMenu = [
  { name: 'All Activities', value: 'all' },
  { name: 'Staff Activities', value: 'is_staff' },
  { name: 'My Activities', value: 'is_owner' },
  { name: 'Draft Activities', value: 'is_draft' },
  { name: 'Private Activities', value: 'is_private' },
  { name: 'Public Activities', value: 'is_public' },
];

function StepHeaderCollapsibleWrapper({
  setTextSizeClass,
  isTuvaJR,
  showTextSizePicker,
  setFilterType,
  filterType,
}) {
  const [classType, setClassType] = useState('');

  useEffect(() => {
    setClassType(
      StepLocalStorageUtil.getTuvaTextChoice()
        ? StepLocalStorageUtil.getTuvaTextChoice()
        : isTuvaJR
        ? 'large'
        : 'standard',
    );
  }, [isTuvaJR]);

  useEffect(() => {
    StepLocalStorageUtil.setTuvaTextChoice(classType);
    let cssClass = '';
    if (classType === 'large') {
      cssClass += ' large-text';
    } else if (classType === 'extralarge') {
      cssClass += ' extra-large-text';
    }
    setTextSizeClass(cssClass);
  }, [classType, setTextSizeClass]);

  if (showTextSizePicker) {
    return (
      <div className="bg-green-1 px-3 py-2 d-flex flex-items-baseline">
        {setFilterType ? (
          <div className="d-flex">
            {filterType && (
              <FilterDropDown
                filterType={filterType}
                filterOptionMenu={filterOptionMenu}
                setFilterType={setFilterType}
              />
            )}
            <TextSizePicker
              setClassType={setClassType}
              classType={classType}
              setTextSizeClass={setTextSizeClass}
              isStaffUser={true}
            />
          </div>
        ) : (
          <TextSizePicker
            setClassType={setClassType}
            classType={classType}
            setTextSizeClass={setTextSizeClass}
          />
        )}
      </div>
    );
  }

  return <></>;
}

export default StepHeaderCollapsibleWrapper;
