import parse from 'html-react-parser';
import Constants from './Constants';

export default {
  convertNewLineToHTMLBreak: function (text) {
    return parse(text.replace(/(?:\r\n|\r|\n)/g, '<br />'));
  },

  getAlphabetFromNumbers(indexes, separator) {
    if (typeof indexes === 'string' || typeof indexes === 'number') {
      indexes = parseInt(indexes) - 1;
      return this.getAlphabetFromNumber(indexes);
    } else if (Array.isArray(indexes)) {
      var result = [];
      indexes.forEach((_index, key) => {
        _index = parseInt(_index) - 1;
        result.push(this.getAlphabetFromNumber(_index));
      });
      if (separator) {
        return result.join(separator);
      } else {
        return result;
      }
    } else {
      return indexes;
    }
  },

  getAlphabetFromNumber(index) {
    if (index >= 0) {
      return String.fromCharCode(65 + index);
    } else {
      return '';
    }
  },

  getOptionFromIndex(options, optionIndexes) {
    let ans = [];
    if (typeof optionIndexes === 'object' && optionIndexes.length > 0) {
      optionIndexes.map(function (index) {
        ans.push(options[index - 1]);
      });
    } else if (typeof optionIndexes === 'number' || typeof n === 'string') {
      ans.push(options[optionIndexes - 1]);
    }
    return ans;
  },

  dataConstruction(userAnswerReview) {
    let response = '';
    let xAxisResponse = '';
    let yAxisResponse = '';

    if (userAnswerReview && userAnswerReview.value) {
      let axisValues = userAnswerReview.value;
      if (axisValues && axisValues.xAxis) {
        xAxisResponse = 'X Axis - ' + axisValues.xAxis.join(',') + '<br/>';
      }

      if (axisValues && axisValues.yAxis) {
        yAxisResponse = 'Y Axis - ' + axisValues.yAxis.join(',');
      }

      if (xAxisResponse.length > 0 || yAxisResponse.length > 0) {
        response = xAxisResponse + ' ' + yAxisResponse;
      } else {
        response = 'Wrong attributes on axis.';
      }
    } else {
      response = 'Wrong attributes on axis.';
    }

    return response;
  },

  getRandomString() {
    return Math.random().toString(36).substring(2, 7);
  },

  convertObjectToFormData(obj) {
    var form_data = new FormData();
    for (var key in obj) {
      form_data.append(key, obj[key]);
    }

    return form_data;
  },

  getPreferredSubjectCategory(subjectCategories) {
    const counts = subjectCategories.reduce(function (categoryCounts, elem) {
      const existingCategoryCount = categoryCounts.find(
        cc => cc.category === elem.category,
      );
      if (existingCategoryCount) {
        existingCategoryCount.count += 1;
      } else {
        categoryCounts.push({ category: elem.category, count: 1 });
      }
      return categoryCounts;
    }, []);

    //get category which has more subjects will be shown as default filter type in content library
    const maxCc = counts.reduce(
      (maxCc, cc) => (maxCc.count < cc.count ? cc : maxCc),
      { category: Constants.CONTENT_LIBRARY.OTHER_SUBJECT_CATEGORY, count: 0 },
    );
    return maxCc.category === Constants.CONTENT_LIBRARY.OTHER_SUBJECT_CATEGORY
      ? Constants.CONTENT_LIBRARY.DEFAULT_SUBJECT_CATEGORY
      : maxCc.category.toLowerCase();
  },

  validateZeroOrPositiveNumber(myNumber) {
    const numberRegEx = /^[0-9]/;
    return numberRegEx.test(String(myNumber).toLowerCase());
  },

  hasAccessToContentBuilder(user, is_premium_dataset, is_jr_dataset) {
    return (
      user &&
      user.type === 'teacher' &&
      (user.is_staff ||
        user.is_superuser ||
        (is_premium_dataset
          ? is_jr_dataset
            ? user.is_jr
            : user.is_premium
          : true))
    );
  },

  debounce(callback, delay = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    };
  },

  normalizeAppConfData(data) {
    let configData = {};
    if (data.appname === 'partner') {
      configData['isPartner'] = true;
    } else if (data.appname === 'doctrina') {
      configData['isDoctrina'] = true;
    }
    configData['google'] = data.google ? data.google : '';
    configData['onedrive'] = data.onedrive ? data.onedrive : '';
    configData['config'] = data.config ? data.config : '';
    return configData;
  },

  getActiveTabForClassroom(object_type) {
    return object_type === 'taskassessment' ? 'Assessments' : 'Assignments';
  },

  // To replace enter key char to html break tag
  convertEnterKeyCharToBr: function (text) {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
  },

  // To format query params for api
  formatQueryParam: function (params) {
    let queryParams = Object.entries(params)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return queryParams;
  },
};
