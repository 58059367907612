import React from 'react';
import PropTypes from 'prop-types';
import { Form, BasicModal, Input } from '@tuva-ui/components/';

function SavePlotModal({ plotTitle, isPartner, onSubmit, onClose }) {
  return (
    <BasicModal isOpen onClose={() => onClose()}>
      <BasicModal.Header headerText={plotTitle ? 'Update Plot' : 'Save Plot'} />
      <BasicModal.Body>
        <div className="col-12">
          <Form
            type={Form.TYPE.SIMPLE}
            submitText={plotTitle ? 'Update Plot' : 'Save as New'}
            layout={Form.LAYOUT.VERTICAL}
            onSubmit={data =>
              isPartner
                ? onSubmit(data.plotTitle, data.note)
                : onSubmit(data.plotTitle)
            }
          >
            <Form.Item
              label="Plot Title *"
              name="plotTitle"
              defaultValue={plotTitle}
              rules={[
                { required: true, message: 'Enter Plot Title' },
                { minLength: 4, message: 'Enter minimum 4 characters.' },
              ]}
            >
              <Input.Text />
            </Form.Item>
            {isPartner && (
              <Form.Item label="Notes" name="note">
                <Input.TextArea rows={3} placeholder={'Notes'} />
              </Form.Item>
            )}
          </Form>
        </div>
      </BasicModal.Body>
    </BasicModal>
  );
}

SavePlotModal.propTypes = {
  plotTitle: PropTypes.string,
  isPartner: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

SavePlotModal.defaultProps = {
  isPartner: false,
};

export default SavePlotModal;
