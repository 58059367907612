import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
  MarkdownEditor,
  QuestionMarkIcon,
  ToolTip,
  Button,
} from '@tuva-ui/components';
import GraphType from './GraphType';
import './index.scss';

let errorOperation = {
  addClass: function (element, className) {
    element.className += ` ${className}`;
    element.focus();
  },
  removeClass: function (element, className) {
    element.classList.remove(className);
  },
};

let questionInput = function (
  qnsInputRef,
  type,
  question,
  questionId,
  qnsInputLabelRef = null,
) {
  useEffect(() => {
    qnsInputRef.current.selectionStart = qnsInputRef.current.value.length;
    qnsInputRef.current.selectionEnd = qnsInputRef.current.value.length;
    qnsInputRef.current.focus();
  }, []);

  return (
    <textarea
      ref={qnsInputRef}
      className={
        'width-full form-control my-2 cursor-pointer question-text-edit'
      }
      id={'question-input-' + questionId}
      type={'text'}
      onPaste={e => {
        // To trim new line from pasted values
        setTimeout(() => {
          if (e?.target?.value) {
            e.target.value = e.target.value.replace(/\r?\n|\r/g, ' ').trim();
          }
        }, 50);
      }}
      onChange={() => {
        qnsInputRef.current.classList.remove('border');
        qnsInputRef.current.classList.remove('border-red');
        qnsInputLabelRef.current.classList.remove('text-red');
      }}
      onKeyPress={e => {
        e.key === 'Enter' && e.preventDefault();
      }}
      rows={type === 'textarea' ? 3 : 2}
      defaultValue={question}
    ></textarea>
  );
};

let blockHeader = function (
  onDelete,
  question_id,
  tooltipText,
  onMoveUpOrDown,
  disableMoveUp,
  disableMoveDown,
  disableDeleteBtn,
) {
  return (
    <div className="d-flex flex-items-baseline flex-justify-between question-block-header">
      <p className="mb-3 font-weight-bold">
        Question*
        <QuestionMarkIcon tooltipText={tooltipText}></QuestionMarkIcon>
      </p>
      <div>
        <button
          disabled={disableMoveUp}
          onClick={() => onMoveUpOrDown('up', question_id)}
          className="bg-transparent border-0 ml-4 mr-1 ti ti-chevron-up"
        ></button>
        <button
          disabled={disableMoveDown}
          onClick={() => onMoveUpOrDown('down', question_id)}
          className="bg-transparent border-0 ti ti-chevron-down"
        ></button>
        <button
          disabled={disableDeleteBtn}
          className="bg-transparent border-0 text-right p-1 question-block-btn"
          onClick={e => onDelete(question_id)}
          id={`${question_id}-delete-btn`}
        >
          <span className="ti ti-trash"></span>
        </button>
      </div>
      <ToolTip place="right" />
    </div>
  );
};
function QuestionsBlocks(props) {
  return <div className="question-blocks-wrapper">{props.children}</div>;
}

let CheckBoxORRadio = React.forwardRef((props, ref) => {
  let obj = props.obj;
  let tooltipText = `The multiple choice question is an automatically graded question type.
  Students will be able to select a single answer from a list of options.

  Please remember to select a single correct answer by clicking on a radio button below.`;
  let { bgColor, onMoveUpOrDown, disableMoveUp, disableMoveDown } = props.obj;
  let { questionId, type, question } = obj.value;
  let isCheckbox = type === 'checkbox';
  let optionsObj = obj.value.options;
  if (optionsObj.length === 0) {
    optionsObj.push(undefined);
    optionsObj.push(undefined);
  }
  let findAnswer = '';
  if (obj.value.answer && obj.value.answer !== '' && obj.value.answer.length) {
    if (isCheckbox) {
      findAnswer = obj.value.answer;
      tooltipText = `The multiple answer question is an automatically graded question type. Students will be able to select multiple answers from a list of options.

      Please remember to select multiple correct answers by clicking on the checkboxes below.
      `;
    } else {
      findAnswer = '';
    }
  } else {
    findAnswer = obj.value.answer;
  }

  let [answer, setAnswer] = useState(findAnswer);
  let [options, setOptions] = useState(optionsObj);
  let qnsInputRef = useRef();
  let questionWrapperRef = useRef();
  let qnsInputLabelRef = useRef();
  let optionsInputRef = useRef([]);
  let optionsCheckBoxRef = useRef([]);
  let checkBoxLabelRef = useRef();

  useImperativeHandle(ref, () => ({
    getValue: updateErrors => {
      if (updateErrors) {
        for (let i = 0; i < optionsInputRef.current.length; i++) {
          if (qnsInputRef.current.value.trim() === '') {
            errorOperation.addClass(qnsInputRef.current, 'border-red');
            errorOperation.addClass(qnsInputLabelRef.current, 'text-red');
            qnsInputRef.current.focus();
            return 0;
          }
          let element = optionsInputRef.current[i];
          if (element && element.value.trim() === '') {
            element.focus();
            errorOperation.addClass(element, 'border-red');
            return 0;
          }
        }
        if ((isCheckbox && !answer.length) || (!isCheckbox && answer === '')) {
          errorOperation.addClass(checkBoxLabelRef.current, 'text-red');
          for (let i = 0; i < optionsCheckBoxRef.current.length; i++) {
            let element = optionsCheckBoxRef.current[i];
            errorOperation.addClass(element, 'border-red');
          }
          return 0;
        }
      }
      return {
        options: options,
        answer: answer,
        type,
        questionId,
        question: qnsInputRef.current.value,
        category: '',
      };
    },
    scrollToVisible: () => {
      questionWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    },
  }));

  let optionsLayout = options.map((option, index) => (
    <label
      className={`d-block cursor-pointer d-flex flex-items-center ${
        index === 1 ? 'mb-2' : 'mb-2 mt-2'
      }  text-normal`}
      key={'label+' + type + index}
    >
      <input
        className={`mr-2 custom-${type}`}
        name={questionId}
        type={type}
        ref={el => (optionsCheckBoxRef.current[index] = el)}
        onChange={e => {
          let answerIndex = index + 1;
          if (isCheckbox) {
            const { checked } = e.target;
            if (checked) {
              setAnswer([...answer, answerIndex]);
            } else {
              setAnswer(answer.filter(i => i !== answerIndex));
            }
            for (let i = 0; i < optionsCheckBoxRef.current.length; i++) {
              let element = optionsCheckBoxRef.current[i];
              if (element) {
                errorOperation.removeClass(element, 'border-red');
              }
            }
            checkBoxLabelRef.current.classList.remove('text-red');
          } else {
            setAnswer(answerIndex);
            for (let i = 0; i < optionsCheckBoxRef.current.length; i++) {
              let element = optionsCheckBoxRef.current[i];
              if (element) {
                errorOperation.removeClass(element, 'border-red');
              }
            }
            checkBoxLabelRef.current.classList.remove('text-red');
          }
        }}
        defaultChecked={
          isCheckbox
            ? answer.includes(index + 1)
            : answer !== '' && index + 1 === answer
        }
        id={`${questionId}-${
          isCheckbox ? 'checkbox-btn' : 'radio-btn'
        }-${index}`}
      />
      <input
        className={'width-full form-control question-text-edit'}
        type={'text'}
        value={option || ''}
        ref={el => (optionsInputRef.current[index] = el)}
        onChange={e => {
          optionsInputRef.current[index].classList.remove('border-red');
          let tmpArray = JSON.parse(JSON.stringify(options));
          tmpArray[index] = e.target.value;
          setOptions(tmpArray);
        }}
        id={`${questionId}-${
          isCheckbox ? 'checkbox-option' : 'radio-option'
        }-${index}`}
      ></input>
      <div className="d-flex ml-1" style={{ minWidth: '43px' }}>
        {options.length !== 1 && (
          <Button
            className="bg-transparent border-0"
            id={`${questionId}-${
              isCheckbox ? 'checkbox-delete-option' : 'radio-delete-option'
            }-${index}`}
            onClick={e => {
              e.preventDefault();
              if (isCheckbox) {
                let ind = answer.indexOf(index + 1);
                if (ind !== -1) {
                  answer.splice(ind, 1);
                  optionsCheckBoxRef.current[index].checked = false;
                  setAnswer(answer);
                }
              } else {
                if (answer === index + 1) {
                  setAnswer('');
                  optionsCheckBoxRef.current[index].checked = false;
                }
              }
              let tmpArray = JSON.parse(JSON.stringify(options));
              tmpArray.splice(index, 1);
              setOptions(tmpArray);
            }}
          >
            <span className="ti ti-trash"></span>
          </Button>
        )}
        {index === options.length - 1 && (
          <Button
            className="cursor-pointer bg-transparent border-0"
            onClick={e => {
              e.preventDefault();
              let tmpArray = JSON.parse(JSON.stringify(options));
              tmpArray.push(undefined);
              setOptions(tmpArray);
            }}
            id={`${questionId}-${
              isCheckbox ? 'checkbox-add-option' : 'radio-add-option'
            }-${index}`}
          >
            <span className="ti ti-add-plain"></span>
          </Button>
        )}
      </div>
    </label>
  ));
  return (
    <div
      className={`question-radio-block px-3 pb-2 ${bgColor}`}
      ref={questionWrapperRef}
      id={questionId}
    >
      {blockHeader(
        obj.onDelete,
        questionId,
        tooltipText,
        onMoveUpOrDown,
        disableMoveUp,
        disableMoveDown,
      )}
      <p className="mb-0" ref={qnsInputLabelRef}>
        Write your question below.
      </p>
      {questionInput(qnsInputRef, type, question, questionId, qnsInputLabelRef)}
      <div className="checkbox-question-block">
        <p ref={checkBoxLabelRef} className="mb-0 mt-2">
          Add answer options below and then choose
          {isCheckbox
            ? ' at least one correct answer by clicking one or more checkboxes.'
            : ' one correct answer by clicking the radio button.'}
        </p>
        {optionsLayout}
      </div>
    </div>
  );
});

let MarkdownEditorInput = React.forwardRef((obj, ref) => {
  let {
    autocompletionList,
    value,
    onDelete,
    bgColor,
    onMoveUpOrDown,
    disableMoveUp,
    disableMoveDown,
    disableDeleteBtn,
  } = obj;
  let { text, instructionId, type } = value;
  let inputRef = useRef();
  let questionWrapperRef = useRef();

  useImperativeHandle(ref, () => ({
    getValue: showError => {
      if (inputRef.current.getValue() === '') {
        if (showError) {
          inputRef.current.applyError();
        }
        return 0;
      }
      return {
        type,
        instructionId,
        text: inputRef.current.getValue(),
      };
    },
    scrollToVisible: () => {
      questionWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    },
  }));
  return (
    <div
      className={`question-instruction-block px-3 pb-2 ${bgColor}`}
      id={instructionId}
      ref={questionWrapperRef}
    >
      <div className="d-flex flex-items-baseline flex-justify-between question-block-header">
        <div className="mb-2 font-weight-bold">
          Add your Instruction*
          <QuestionMarkIcon
            tooltipText={
              'We recommend keeping instructions as short as possible. Break long paragraphs into multiple paragraphs with many line breaks.If you find you’re writing more than about a hundred words, consider breaking the step into multiple steps.'
            }
          />
        </div>
        <div>
          <button
            disabled={disableMoveUp}
            onClick={() => onMoveUpOrDown('up', instructionId, 'instruction')}
            className="bg-transparent border-0 ml-4 mr-1 ti ti-chevron-up"
          ></button>
          <button
            disabled={disableMoveDown}
            onClick={() => onMoveUpOrDown('down', instructionId, 'instruction')}
            className="bg-transparent border-0 ti ti-chevron-down"
          ></button>
          <Button
            disabled={disableDeleteBtn}
            className="bg-transparent border-0 text-right p-1 question-block-btn"
            onClick={e => onDelete(instructionId)}
          >
            <span className="ti ti-trash"></span>
          </Button>
        </div>
      </div>
      <div className="bg-white">
        <MarkdownEditor
          ref={inputRef}
          autocompletionList={autocompletionList}
          initialValue={text}
          inputId={'markdown-' + instructionId}
        />
      </div>
    </div>
  );
});

let TextORTextArea = React.forwardRef(function (props, ref) {
  let obj = props.obj;
  let { bgColor, onMoveUpOrDown, disableMoveUp, disableMoveDown } = props.obj;
  let { questionId, type, rows, question } = obj.value;
  let qnsInputRef = useRef();
  let qnsInputLabelRef = useRef();
  let questionWrapperRef = useRef();

  let tooltipText = `The long answer will give your students enough space to answer your question with several sentences,
  allowing them to explain their thinking and to justify their answer.`;
  if (type === 'text') {
    tooltipText = `The short answer question will give your students enough space to answer your question with a few words or numbers.`;
  }
  useImperativeHandle(ref, () => ({
    getValue: updateErrors => {
      if (updateErrors) {
        if (qnsInputRef.current.value.trim() === '') {
          errorOperation.addClass(qnsInputRef.current, 'border-red');
          errorOperation.addClass(qnsInputLabelRef.current, 'text-red');
          qnsInputRef.current.focus();
          return 0;
        }
      }
      return {
        type,
        questionId,
        question: qnsInputRef.current.value,
        rows,
        category: '',
      };
    },
    scrollToVisible: () => {
      questionWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    },
  }));
  return (
    <div
      className={`question-radio-block px-3 pb-2 ${bgColor}`}
      id={questionId}
      ref={questionWrapperRef}
    >
      {blockHeader(
        obj.onDelete,
        questionId,
        tooltipText,
        onMoveUpOrDown,
        disableMoveUp,
        disableMoveDown,
      )}
      <p className="mb-0" ref={qnsInputLabelRef}>
        Write your question below.
      </p>
      {questionInput(qnsInputRef, type, question, questionId, qnsInputLabelRef)}
    </div>
  );
});

let GraphQuestion = React.forwardRef(function (props, ref) {
  let obj = props.obj;
  let { bgColor, onMoveUpOrDown, disableMoveUp, disableMoveDown } = props.obj;
  let { questionId, type, rows, question } = obj.value;
  let qnsInputRef = useRef();
  let qnsInputLabelRef = useRef();
  let questionWrapperRef = useRef();

  //TODO: update tooltip
  let tooltipText = `The graph answer will give your students space to submit their graph as an image.`;

  useImperativeHandle(ref, () => ({
    getValue: updateErrors => {
      if (updateErrors) {
        if (qnsInputRef.current.value.trim() === '') {
          errorOperation.addClass(qnsInputRef.current, 'border-red');
          errorOperation.addClass(qnsInputLabelRef.current, 'text-red');
          qnsInputRef.current.focus();
          return 0;
        }
      }
      return {
        type,
        questionId,
        question: qnsInputRef.current.value,
        rows,
        category: '',
      };
    },
    scrollToVisible: () => {
      questionWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    },
  }));
  return (
    <>
      <div
        className={`question-radio-block px-3 pb-2 ${bgColor}`}
        id={questionId}
        ref={questionWrapperRef}
      >
        {blockHeader(
          obj.onDelete,
          questionId,
          tooltipText,
          onMoveUpOrDown,
          disableMoveUp,
          disableMoveDown,
        )}
        <p className="mb-0" ref={qnsInputLabelRef}>
          Write your question below.
        </p>
        {questionInput(
          qnsInputRef,
          type,
          question,
          questionId,
          qnsInputLabelRef,
        )}

        <GraphType />
      </div>
    </>
  );
});

let CheckBox = React.forwardRef((obj, ref) => {
  return <CheckBoxORRadio obj={obj} ref={ref}></CheckBoxORRadio>;
});

let Text = React.forwardRef(function (obj, ref) {
  return <TextORTextArea obj={obj} ref={ref}></TextORTextArea>;
});

let TextArea = React.forwardRef(function (obj, ref) {
  return <TextORTextArea obj={obj} ref={ref}></TextORTextArea>;
});

let Radio = React.forwardRef((obj, ref) => {
  return <CheckBoxORRadio obj={obj} ref={ref}></CheckBoxORRadio>;
});

let Graph = React.forwardRef((obj, ref) => {
  return <GraphQuestion obj={obj} ref={ref}></GraphQuestion>;
});

QuestionsBlocks.Text = Text;
QuestionsBlocks.TextArea = TextArea;
QuestionsBlocks.Radio = Radio;
QuestionsBlocks.CheckBox = CheckBox;
QuestionsBlocks.Graph = Graph;
QuestionsBlocks.MarkdownEditor = MarkdownEditorInput;

QuestionsBlocks.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([])).isRequired,
  // children: PropTypes.arrayOf(PropTypes.oneOfType([Radio,CheckBox])), TODO: need to check as it throws warning
};

Radio.propTypes = {
  value: PropTypes.shape({
    questionId: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
  }),
};

export default QuestionsBlocks;
