import Edit from './Edit';
import Delete from './Delete';
import Download from './Download';
import View from './View';

function Actions({ className, children }) {
  return <div className={className}>{children}</div>;
}

Actions.Edit = Edit;
Actions.Delete = Delete;
Actions.Download = Download;
Actions.View = View;

export default Actions;
