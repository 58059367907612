import React from 'react';
import PropTypes from 'prop-types';

function ContentType({ objectType, isIconOnly }) {
  if (!objectType) return <></>;

  let contentTypeIcon = '',
    contentTypeLabel = '';

  switch (objectType) {
    case 'dataset':
      contentTypeIcon = 'ti-dataset';
      contentTypeLabel = 'Dataset';
      break;
    case 'activity':
      contentTypeIcon = 'ti-activities';
      contentTypeLabel = 'Activity';
      break;
    case 'datastory':
    case 'lesson':
      contentTypeIcon = 'ti-datastories';
      contentTypeLabel = 'Data Story';
      break;
    case 'task':
      contentTypeIcon = 'ti-tasks';
      contentTypeLabel = 'Task';
      break;
    case 'taskassessment':
      contentTypeIcon = 'ti-assessments';
      contentTypeLabel = 'Assessment';
      break;
    case 'course':
      contentTypeIcon = 'ti-dataset';
      contentTypeLabel = 'Dataset';
      break;
    case 'collection':
      contentTypeIcon = 'ti-collections';
      contentTypeLabel = 'Collection';
      break;
    default:
      break;
  }

  return isIconOnly ? (
    <i className={`ti ${contentTypeIcon}`}></i>
  ) : (
    <span className="p2 m-0 text-gray d-flex flex-items-center">
      <i className={`ti ${contentTypeIcon} mr-2 d-inline-flex`}></i>
      {contentTypeLabel}
    </span>
  );
}

ContentType.propTypes = {
  objectType: PropTypes.string,
  isIconOnly: PropTypes.bool,
};

ContentType.defaultProps = {
  objectType: undefined,
  isIconOnly: false,
};

export default ContentType;
