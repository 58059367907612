import React from 'react';
import { Button } from '@tuva-ui/components/';
function TextSizePickerButton({ toggleShowTextSizePicker, className }) {
  return (
    <Button
      className={`ml-3 border-0 s2 ${className ? className : ''}`}
      onClick={e => {
        toggleShowTextSizePicker();
      }}
      style={{ background: 'none' }}
    >
      . . .
    </Button>
  );
}

export default TextSizePickerButton;
