import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import { findDOMNode } from 'react-dom';
import loadScript from 'load-script';
import { Loading } from '@tuva-ui/components';

window.React = React;
window.ReactDOM = ReactDOM;
// TODO: replace findDOMNode with useRef
window.ReactDOM.findDOMNode = findDOMNode;

const TOOLS_URL = window.TUVA_AUTHORING_TOOL_URL;

let AuthToolMemoed;

// TODO: Merge this with the common TuvaDataTool in components app
const AuthTool = React.forwardRef(function (props, ref) {
  const {
    columnIds,
    columnNames,
    rowData,
    metaData,
    plotState,
    className,
    style,
    customFields,
    insertMode,
    defaultView,
    onToolsLoaded,
    experimentalFeatures,
  } = props;
  const [isToolsLoaded, setIsToolsLoaded] = useState(!!window.AuthoringTool);

  useEffect(() => {
    if (!window.AuthoringTool) {
      loadScript(TOOLS_URL, function () {
        AuthToolMemoed = React.memo(window.AuthoringTool);
        setIsToolsLoaded(true);
        if (onToolsLoaded) {
          onToolsLoaded();
        }
      });
    } else if (onToolsLoaded) {
      onToolsLoaded();
    }
  }, []);

  const onAddAttrClick = useCallback(cb => {
    cb();
  }, []);

  return (
    <div style={style} className={className || ''}>
      {isToolsLoaded ? (
        <AuthToolMemoed
          ref={ref}
          defaultView={defaultView}
          insertMode={insertMode}
          columnIds={columnIds}
          columnNames={columnNames}
          rowData={rowData}
          metaData={metaData}
          plotState={plotState}
          isTuva={true}
          experimentalFeatures={experimentalFeatures}
          customFields={customFields}
          onAddAttrClick={onAddAttrClick}
        />
      ) : (
        <div className="py-6">
          <Loading show={true} />
        </div>
      )}
    </div>
  );
});

export default AuthTool;
