import React from 'react';
import { Button, Util, ClassroomIcon } from '@tuva-ui/components';

function ClassRooms({ classrooms, objectType, assignToClass }) {
  const activeTab = Util.getActiveTabForClassroom(objectType);
  return (
    <div className="d-flex flex-justify-center">
      <ul
        className="pl-4 pr-4 mb-6"
        style={{
          listStyle: 'none',
        }}
      >
        {classrooms.map((classroom, index) => {
          const className = index === 0 ? 'mb-3' : 'my-3';
          return (
            <li key={classroom.id} className={`${className} mx-auto d-flex`}>
              {classroom.assignment_id > 0 && (
                <a
                  href={`/dashboard/classrooms/${classroom.id}?assignment=${classroom.assignment_id}&activetab=${activeTab}`}
                  style={{ width: '350px' }}
                  className="btn btn-gold btn-md text-center css-truncate css-truncate-target width-fit"
                  title={classroom.section}
                >
                  {classroom.grade_name} - {classroom.section}
                </a>
              )}
              {!classroom.assignment_id && (
                <Button
                  type="button"
                  style={{ width: '350px' }}
                  onClick={e => assignToClass(classroom)}
                  className="btn btn-md btn-primary css-truncate css-truncate-target width-fit"
                  title={classroom.section}
                >
                  {classroom.grade_name} - {classroom.section}
                </Button>
              )}
              <span className="p-1">
                {classroom.gclass_id && (
                  <ClassroomIcon
                    provider={classroom.provider}
                    width="25px"
                    style={{
                      textTransform: 'capitalize',
                      verticalAlign: 'middle',
                    }}
                  />
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ClassRooms;
