import React, { useRef, useState, useEffect } from 'react';
import './style.scss';
/**
 * ComponentVisible Hook
 * @param {boolean} initialIsVisible
 */
function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

/**
 * FilterDropdown Functional Component
 * @param {*} props
 */
function FilterDropDown({ filterOptionMenu, filterType, setFilterType }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const currentStepIndex = filterOptionMenu.findIndex(
    step => step.value === filterType,
  );

  if (filterOptionMenu.length === 0) {
    return '';
  }

  return (
    <div
      className="border d-flex flex-items-center rounded-1 staff-filter-selector step-selector mr-1 mr-lg-2"
      onClick={e => {
        setIsComponentVisible(true);
      }}
    >
      <span> {filterOptionMenu[currentStepIndex].name}</span>
      <div className="dropdown-caret ml-1"></div>
      <ul
        className="m-0"
        ref={ref}
        style={{ display: isComponentVisible ? 'block' : 'none' }}
      >
        {filterOptionMenu.map((filterOption, index) => {
          return (
            <li
              key={index}
              className={
                'list-style-none' +
                (filterOption.value === filterOptionMenu[currentStepIndex].value
                  ? ' text-bold'
                  : '')
              }
              onClick={e => {
                e.stopPropagation();
                setIsComponentVisible(false);
                setFilterType(filterOption.value);
              }}
            >
              {filterOption.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default FilterDropDown;
