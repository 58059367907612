import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@tuva-ui/components';

const Switch = ({ onSwitch, isOn }) => {
  return (
    <div className="btn-grouped btngroup">
      <Button
        onClick={() => onSwitch(true)}
        className={`btn btngroup-item m-0 ${isOn ? 'active' : ''}`}
        type="button"
      >
        On
      </Button>
      <Button
        onClick={() => onSwitch(false)}
        className={`btn btngroup-item m-0 ${isOn ? '' : 'active'}`}
        type="button"
      >
        Off
      </Button>
    </div>
  );
};

Switch.propTypes = {
  onSwitch: PropTypes.func,
  isOn: PropTypes.bool,
};

Switch.defaultProps = {
  isOn: false,
};

export default Switch;
