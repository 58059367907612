import React from 'react';
import PropTypes from 'prop-types';
import { MarkdownInline } from '../../../Markdown';

const Name = ({ title, isPlot, isChild, url, isPartnerAPP, isInsertData }) => {
  let className = 'ti-folder';
  if (isPartnerAPP) {
    className = isInsertData ? 'ti-insert-data' : 'ti-dataset';
  }

  return (
    <>
      {(isPlot || isChild) && (
        <div className={`p-2 ${isChild && isPlot ? 'pl-4' : ''}`}></div>
      )}
      <span className="pr-2">
        <i
          className={`ti ${
            isPlot ? 'ti-chart' : isChild ? 'ti-copy' : className
          }`}
        />
      </span>
      <a
        title={title}
        href={url}
        rel="noreferrer"
        className="pr-1 css-truncate css-truncate-target"
        style={{ maxWidth: '80%' }}
      >
        <MarkdownInline value={title} />
      </a>
    </>
  );
};

Name.propTypes = {
  title: PropTypes.string,
  isPlot: PropTypes.bool,
  isChild: PropTypes.bool,
  url: PropTypes.string,
};

Name.defaultProps = {
  isPlot: false,
  isChild: false,
};

export default Name;
